import Error, { ErrorPageButtonKey } from '@components/dashboard/content/Error';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';

const Custom404 = () => {
  return (
    <Error
      statusCode={404}
      buttons={[ErrorPageButtonKey.HOME, ErrorPageButtonKey.PREVIOUS]}
    ></Error>
  );
};

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'errorPage',
        'button',
      ])),
    },
  };
};
